<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span @click="$router.go(-1)" style="cursor: pointer;">成绩管理</span> / 查看试卷
      </template>
    </HeaderBox>
    <!-- 头部 -->
    <div class="paper_title">
      <h3>{{data.paperName}}</h3>
      <h3>总计得分：{{data.totalScore}}分</h3>
    </div>

    <!-- 答题详情 -->
    <div class="answer_box">
      <div class="answer_item" v-for="(item,index) in stemList" :key="index">
        <p class="subject">{{index + 1}}. {{item.title}}({{item.score}}分)</p>
        <div class="option">
          <span class="option_item" v-for="(i,index) in item.optionList" :key="index">{{index==0?'A':index==1?'B':index==2?'C':index==3?'D':index==4?'E':index==5?'F':index==6?'G':'H'}}. {{i.optionName}}</span>
        </div>
        <div class="detail">
          <div>
            <p>正确答案：{{item.answer}}</p>
            <p>学员答案：{{item.checked ? item.checked : '未选'}}</p>
          </div>
          <span :class="item.checked ? (item.answer===item.checked?'bg_true':'bg_false') : 'bg_null'">{{ item.checked ? (item.answer === item.checked?'正确':'错误') : '未选'}}</span>
        </div>
      </div>
      <a-spin class="spin" v-if="loading" />
    </div>
    <a-button class="all_boder_btn" @click="$router.go(-1)">返回</a-button>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      data: [],
      stemList: [],
      loading: false,
    }
  },
  // 事件处理器
  methods: {
    getList(id){
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/exam/examinee/paper/detail",
        params: {
          userPaperId: id,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.data = res.data;
          this.stemList = this.data.stemList
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.id = this.$route.query.userpaperid;
    this.getList(this.id);
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.all_main_wrap{
  .paper_title{
    background: #C4C4C4;
    padding: 16px 26px;
    display: flex;
    justify-content: space-between;
    h3{
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
    }
  }
  .answer_box{
    position: relative;
    min-height: 400px;
    .spin{
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  .answer_item{
    margin: 14px 0;
    color: #333333;
    .subject{
      font-weight: 400;
      line-height: 22px;
    }
    .option{
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      .option_item{
        display: inline-block;
        font-size: 14px;
        line-height: 30px;
        font-weight: 400;
        width: calc(100% / 2);
      }
    }
    .detail{
      margin-top: 10px;
      padding: 15px 50px;
      background: rgba(196, 196, 196, 0.15);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      p{
        line-height: 25px;
      }
      span{
        display: inline-block;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-35deg);
        color: #ffffff;
      }
      .bg_true{
        background: #266ACE;
      }
      .bg_false{
        background: #CE2626;
      }
      .bg_null{
        background: #FF8C00;
      }
    }
  }
}
</style>
